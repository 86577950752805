import { Stack } from '@chakra-ui/react';
import React from 'react';
import SimpleImageSlider from 'react-simple-image-slider';
export default function ImageSlider() {
  const images = [
    {
      url: 'https://i.ibb.co/RPdLKCh/dreamstime-xxl-94133634.jpg',
    },
    {
      url: 'https://i.ibb.co/BCx0SwR/dreamstime-xxl-116914.jpg',
    },
    {
      url: 'https://i.ibb.co/vcJPy4r/Attachment-1.jpg',
    },
  ];

  return (
    <Stack position="absolute" w="100%">
      <SimpleImageSlider
        width="100%"
        height="100vh"
        images={images}
        autoPlay={true}
        autoPlayDelay={3}
      />
    </Stack>
  );
}
